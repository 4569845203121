<!-- Created by henian.xu on 2017/10/1. -->

<template>
  <Page class="bc-g1">
    <container ref="container">
      <div class="ranking-label">
        <i class="f-icon">&#xf059;</i> 排行榜 <i class="f-icon">&#xf05a;</i>
      </div>

      <div class="btn-group average interval pa-a">
        <div
          class="btn bor radius all"
          :class="+timeType===1?'btn-second':'link btn-g5'"
          @click="onTimeType(1)"
        >
          日
        </div>
        <div
          class="btn bor radius all"
          :class="+timeType===2?'btn-second':'link btn-g5'"
          @click="onTimeType(2)"
        >
          月
        </div>
        <div
          class="btn bor radius all"
          :class="+timeType===3?'btn-second':'link btn-g5'"
          @click="onTimeType(3)"
        >
          年
        </div>
      </div>

      <user-box-ranking class="user-box-wrap">
        <div class="price">
          {{ distributorInfo.totalDistributionAmount }}
        </div>
        <div class="badge badge-second">
          {{ distributorInfo.ranking }}
        </div>
      </user-box-ranking>

      <div class="ranking-table">
        <table>
          <colgroup>
            <col width="120px">
            <col width="100px">
            <col>
            <col width="200">
          </colgroup>
          <thead>
            <tr>
              <th>排名</th>
              <th>头像</th>
              <th>昵称</th>
              <th class="ta-r">
                分享金额
              </th>
            </tr>
          </thead>
          <tbody
            v-if="rankingList.length"
          >
            <tr
              v-for="(item,index) in rankingList"
              :key="index"
            >
              <td>
                <div class="medal">
                  {{ index + 1 }}
                </div>
              </td>
              <td>
                <div
                  class="avatar"
                  :style="`background-image: url(${item.avatar||defaultImg});`"
                />
              </td>
              <td>
                <div class="tc-main">
                  {{ item.nickName }}
                </div>
              </td>
              <td class="ta-r">
                <div class="price">
                  {{ item.totalDistributionAmount | price(2,false) }}
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td
                colspan="4"
                class="tc-g6"
              >
                暂无数据
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </container>
  </Page>
</template>

<script>
import pageMixin from '@/mixins/layout/page';
import defaultImg from '@/assets/images/comm/default.jpg';
import userBoxRanking from './_userBoxRanking';

export default {
  name: 'Ranking',
  mixins: [pageMixin],
  components: { userBoxRanking },
  data() {
    return {
      defaultImg,
      userBoxData: null,
      distributorInfo: '',
      rankingList: [],
      timeType: 1,
    };
  },
  methods: {
    getRankingList() {
      this.$api.Buyer.Mb.Distributor.distributorLeaderboardsList({
        leaderboardsType: this.timeType,
      }).then(json => {
        const result = json.data.data;
        this.distributorInfo = result.buyerDistributorLeaderboardsInfo;
        this.userBoxData = {
          userAvatar: this.distributorInfo.avatar,
          userName: this.distributorInfo.nickName,
        };
        this.rankingList = result.distributorLeaderboardsList;
      });
    },
    onTimeType(type) {
      this.timeType = type;
      this.getRankingList();
    },
  },
  created() {
    this.getRankingList();
  },
};
</script>

<style lang="scss">
.user-box-wrap {
  margin-bottom: $margin-big;
  > .user-box {
    padding: $padding;
    border: 0;

    .price {
      color: $color-price;
      font-size: 0.5rem;
      &:before {
        font-size: 0.4rem;
      }
    }
  }
}
</style>
<style lang="scss">
.ranking-label {
  padding: $padding;
  text-align: center;
  color: $color-main;
  font-size: 0.4rem;
  font-weight: bold;
  background-color: #fff;
}

.ranking-table {
  background-color: #fff;
  > table {
    width: 100%;
    border-collapse: collapse;
    tr {
      border-bottom: 1px solid $color-border;
      th,
      td {
        text-align: center;
        padding: $padding $padding-small;
      }
    }
    > thead {
      th {
        font-weight: normal;
        white-space: nowrap;
      }
    }
    > tbody {
      > tr {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          .medal {
            font-weight: bold;
            color: $color-red;
            padding: 0.1rem;
            background: transparent url('~@/assets/images/comm/medal2.png')
              center bottom no-repeat;
            background-size: contain;
          }
        }
        .medal {
          margin: 0 auto;
          width: 0.5rem;
          height: 0.6rem;
          line-height: 0.6rem;
        }
        .avatar {
          width: 0.6rem;
          height: 0.6rem;
          margin: 0 auto;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          border-radius: 50%;
          overflow: hidden;
        }
      }
    }
  }
}
</style>
