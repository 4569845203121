<!-- Created by henian.xu on 2018/12/27. -->

<template>
  <Page :title="pageTitle">
    <Container ref="container">
      <Widget
        v-if="decorationJson"
        :json="decorationJson"
      />
    </Container>
    <PageBar v-if="noBuy">
      <Button
        :to="`/distributor/buyCard/${distributorLevelId}`"
        theme="main"
        label="立即购买"
      />
    </PageBar>
  </Page>
</template>

<script>
import pageMixin from '@/mixins/layout/page';
export default {
  name: 'CardDetail',
  mixins: [pageMixin],
  data() {
    return {
      cardData: {},
    };
  },
  computed: {
    distributorLevelId() {
      return this.$route.params.distributorLevelId;
    },
    decorationJson() {
      return (
        (this.$route.query.pageTitle
          ? this.cardData.distributeIncomeDesc
          : this.cardData.rightsDesc) || ''
      );
    },
    noBuy() {
      return this.$route.query.noBuy === undefined;
    },
    pageTitle() {
      return this.$route.query.pageTitle || this.$route.meta.title;
    },
  },
  methods: {
    getDetailData(id = this.distributorLevelId) {
      return this.$api.Buyer.Mb.Distributor.distributorLevelDetail(id).then(
        json => {
          this.cardData = json.data.data;
        },
      );
    },
  },
  created() {
    this.getDetailData();
  },
};
</script>

<style lang="scss">
</style>
