<!-- Created by henian.xu on 2019/1/10. -->

<template>
  <div class="bestow-item">
    <div class="header">
      <div class="label badge badge-main">
        {{ data.levelName }}
      </div>
      <div class="code">
        优惠码: <span class="tc-main">{{ data.promoCode }}</span>
      </div>
    </div>
    <div
      v-if="data.isUsed"
      class="body"
    >
      <div class="user">
        <div
          class="avatar"
          :style="`background-image: url('${$options.filters.imgCropping(data.avatar||$defaultAvatar,{width:200})}');`"
        />
        <div class="info">
          <div class="nickname">
            {{ data.nickName }}
          </div>
          <Linker
            :to="`tel://+${data.countryCode}${data.mobile}`"
            class="mobile"
          >
            <i class="f-icon">&#xf072;</i>+{{ data.countryCode }}{{ data.mobile }}
          </Linker>
        </div>
      </div>
      <div class="used-time fs-small">
        {{ data.usedTime | moment('YYYY-MM-DD HH:mm:ss') }}
      </div>
    </div>
    <div
      v-else
      class="body"
    >
      <div class="user">
        <div
          class="avatar"
          :style="`background-image: url('${$options.filters.imgCropping(data.avatar||$defaultAvatar,{width:200})}');`"
        />
        <div class="info">
          <div class="nickname tc-g6">
            未使用
          </div>
        </div>
      </div>
      <Button
        ref="copyRecommend"
        :data-clipboard-text="data.promoCode"
        theme="main"
        size="small"
        radius="all"
        label="复制优惠码"
      />
    </div>
  </div>
</template>

<script>
import Clipboard from 'clipboard';
export default {
  name: 'BestowItem',
  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    },
  },
  methods: {
    initClipboard() {
      const { copyRecommend } = this.$refs;
      console.log(copyRecommend);
      const copySuccess = () => {
        this.$messageBox.tips('复制成功', 3000);
      };
      const copyError = () => {
        this.$messageBox.tips('复制失败', 3000);
      };
      const $node = new Clipboard(copyRecommend.$el || copyRecommend);
      $node.on('success', copySuccess);
      $node.on('error', copyError);
    },
  },
  mounted() {
    if (!this.data.isUsed) {
      this.initClipboard();
    }
  },
};
</script>

<style lang="scss">
.bestow-item {
  background-color: #fff;
  + .bestow-item {
    margin-top: $margin;
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $padding;
    border-bottom: 1px solid $color-border;
    > .code {
      color: $gray6;
    }
  }
  > .body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $padding;
    > .user {
      flex: 1 1 1%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      > .avatar {
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background: $gray2 center no-repeat;
        background-size: cover;
      }
      > .info {
        margin-left: $margin;
        > .nickname {
          font-weight: bold;
        }
        > .mobile {
          color: $color-blue;
        }
      }
    }
    .used-time {
      color: $gray6;
      flex: 0 0 auto;
    }
  }
}
</style>
