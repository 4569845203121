<!-- Created by henian.xu on 2017/10/1. -->

<template>
  <Page>
    <canvas
      class="canvas"
      v-show="false"
      ref="graphicCanvas"
      width="1000"
      height="1350"
    />
    <container ref="container">
      <list
        class="nma-a"
        :class="{'ma-bor-b':+type===1}"
      >
        <list-item>
          <div
            class="label"
            slot="label"
          >
            <checkbox
              v-model="type"
              val="0"
              type="radio"
            >
              分享图文
            </checkbox>
          </div>
        </list-item>
      </list>
      <div
        class="share-graphic"
        v-show="+type===0"
      >
        <div class="inner">
          <swiper
            :options="swiperOptions"
          >
            <swiper-slide v-if="good.commisionPrice">
              <div class="inner">
                <img
                  v-if="graphicSrc"
                  :src="graphicSrc"
                >
                <img
                  class="loading"
                  :src="$loadingIcon"
                  v-else
                >
              </div>
            </swiper-slide>
            <swiper-slide
              v-for="(item,index) in goodsSwiperData"
              :key="index"
            >
              <div class="inner">
                <img :src="item.imgUrl | imgCropping">
              </div>
            </swiper-slide>
            <template v-if="!$globalVar.device.isMobile">
              <div
                class="swiper-button-prev"
                slot="button-prev"
              >
                <i class="f-icon">&#xf011;</i>
              </div>
              <div
                class="swiper-button-next"
                slot="button-next"
              >
                <i class="f-icon">&#xf012;</i>
              </div>
            </template>
          </swiper>
          <div class="ta-c pa-a tc-g6">
            <i class="f-icon">&#xf04e;</i> {{ $globalVar.device.isMobile?'长按保存图片':'右键图片另存为' }}
          </div>
        </div>
        <!--<div class="inner ma-t pa-a recommend">
                    <div class="copywriting">
                        【{{ $globalVar.shopData.name }}推荐】<br>
                        {{ good.goodsSubtitle }}<br>
                    </div>
                    <button
                        class="btn btn-main btn-link bor ma-t fn-right radius"
                        ref="copyRecommend"
                        :data-clipboard-text="recommendText"
                    >&lt;!&ndash;<i class="f-icon">&#xf002;</i>&ndash;&gt;复制文案
                    </button>
                </div>-->
        <div
          v-for="(item,index) in goodsShareCopyList"
          :key="index"
          class="inner ma-t pa-a recommend"
        >
          <div class="copywriting">
            <div class="header">
              <div class="label">
                {{ item.copyName }}
              </div>
              <button
                class="btn btn-main btn-link bor radius"
                ref="copyRecommend"
                :data-clipboard-text="item.copyContent"
              >
                <!--<i class="f-icon">&#xf002;</i>-->复制文案
              </button>
            </div>
            <div
              class="body"
              v-if="item.copyContent"
            >
              {{ item.copyContent }}
            </div>
          </div>
        </div>
      </div>

      <list
        v-if="good.commisionPrice"
        class="ma-b"
      >
        <list-item>
          <div
            class="label"
            slot="label"
          >
            <checkbox
              v-model="type"
              val="1"
              type="radio"
            >
              分享链接
            </checkbox>
          </div>
        </list-item>
      </list>
      <div
        class="pa-l pa-r"
        v-show="+type===1"
      >
        <!--<div class="desc">
                    <p>1，当顾客通过您分享的商品链接成功下单后，您可获取相应的佣金。佣金处于冻结状态。</p>
                    <p class="ma-t">2，当顾客确认收货后，冻结的佣金会自动解冻，转到您的账户，可在个人中心查看。</p>
                </div>-->
        <cube-toolbar>
          <div
            class="item"
            @click="onItem('weixin')"
          >
            <i class="f-icon bc-green tc-w">&#xf080;</i>
            <div class="label">
              微信
            </div>
          </div>
          <div
            class="item"
            @click="onItem('copyLink')"
          >
            <button
              ref="copyLink"
              class="f-icon bc-g6 tc-w"
              :data-clipboard-text="sharedData.link"
            >
              &#xf081;
            </button>
            <div class="label">
              复制链接
            </div>
          </div>
          <div
            class="item"
            @click="onItem('QRCode')"
          >
            <i class="f-icon bc-second tc-w">&#xf02b;</i>
            <div class="label">
              二维码
            </div>
          </div>
          <div
            class="item"
            @click="onItem('graphic')"
          >
            <i class="f-icon bc-blue tc-w">&#xf083;</i>
            <div class="label">
              图文二维码
            </div>
          </div>
          <div class="item" />
        </cube-toolbar>
      </div>


      <!--微信分享-->
      <transition name="bounce">
        <div
          class="share-popup"
          v-show="sharePopupShow"
          @click="sharePopupShow=false"
          :style="`background: rgba(0, 0, 0, .8) url('${shareImg}') top center no-repeat; background-size:100% auto;`"
        />
      </transition>
      <!--二维码弹框-->
      <transition name="bounce">
        <div
          class="share-popup"
          v-show="QRCodePopupShow"
          @click.self="QRCodePopupShow=false"
        >
          <img
            ref="QRCode"
            :src="QRCodeSrc"
            width="60%"
            style="border-radius: .15rem;"
          >
          <div class="tc-w pa-l pa-r ma-t ta-c fs-big">
            长按保存图片<br>分享此商品的二维码<br>顾客可通过识别此二维码<br>查看商品详情
          </div>
        </div>
      </transition>
      <!--图文二维码弹框-->
      <transition name="bounce">
        <div
          class="share-popup"
          v-show="graphicPopupShow"
          @click.self="graphicPopupShow=false"
        >
          <img
            ref="graphicImg"
            :src="graphicSrc"
            width="80%"
            style="border-radius: .15rem;"
          >
          <div class="tc-w ma-t ta-c fs-big">
            长按保存图片
          </div>
        </div>
      </transition>
    </container>
    <div class="footer">
      <div
        v-if="$globalVar.device.isWeiXin"
        class="btn btn-g5"
      >
        <div>
          <div>素材下载</div>
          <div class="fs-small">
            (请在浏览器中打开)
          </div>
        </div>
      </div>
      <a
        v-else
        class="btn btn-main"
        :href="`/rpc/buyer/gd/goods/downGoodsImage?id=${good.id}`"
      >素材下载</a>
    </div>
  </Page>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import shareImg from '@/assets/images/share/share-earn.png';
import QRCode from 'qrcode';
import Clipboard from 'clipboard';
// import logo from '@/assets/images/comm/logo.png';
import pageMixin from '@/mixins/layout/page';
// import logo2 from '@/assets/images/comm/logo2.png';

export default {
  name: 'ShareEarn',
  mixins: [pageMixin],
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      type: '0',
      shareData: {},
      sharedData: {},
      good: {},
      goodsSwiperData: [],
      QRCodeSrc: '',
      graphicSrc: '',
      swiperOptions: {
        // pagination: '.swiper-pagination',
        slidesPerView: 1.5,
        centeredSlides: true,
        paginationClickable: true,
        spaceBetween: 30,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      shareImg,
      sharePopupShow: false,
      QRCodePopupShow: false,
      graphicPopupShow: false,
      recommendText: '',
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    shareUuid() {
      return this.$route.query.shareUuid;
    },
    $$copyLink() {
      return this.$refs.copyLink;
    },
    $$copyRecommend() {
      return this.$refs.copyRecommend;
    },
    $$graphicCanvas() {
      return this.$refs.graphicCanvas;
    },
    ctx() {
      return this.$$graphicCanvas.getContext('2d');
    },
    goodsShareCopyList() {
      return this.good.goodsShareCopyList || [];
    },
  },
  methods: {
    getGoodsDetail() {
      this.$api.Buyer.Gd.Goods.goodsDetail({
        id: this.id,
      }).then(json => {
        const result = json.data;
        result.data.goods.goodsImageList.forEach(item => {
          this.goodsSwiperData.push({
            imgUrl: item,
          });
        });
        this.good = result.data.goods;

        // 拼团
        this.isActivityGoods = result.data.isActivityGoods;
        this.activityGoods = {
          ...result.data.activityGoods,
          nowPrice: this.good.price,
        };
        // 分享
        /* this.shareData.title = this.good.goodsName;
                this.shareData.desc = this.good.goodsSubtitle;
                this.shareData.imgUrl = `${this.good.listImageUrl}@400w`;
                this.sharedData = this.$utils.Comm.setShare(this.shareData, false, this.shareUuid); */
        this.setShareData({
          successData: this.sharedData.successData,
          title: this.good.goodsName,
          desc: this.good.goodsSubtitle,
          imgUrl: this.$options.filters.imgCropping(this.good.listImageUrl, {
            width: 400,
          }),
          link: `/goods/detail/${this.id}`,
          uuid: this.shareUuid,
        }).then(data => {
          this.sharedData = data;
          console.log(222, { ...this.sharedData });
          if (this.good.commisionPrice) {
            this.drawGraphic();
          }
        });

        this.recommendText = `【${this.$globalVar.shopData.name}】\n${
          this.good.goodsSubtitle
        }`;
        this.$nextTick(() => {
          this.initClipboard();
        });
      });
    },
    drawText(t, x, y, w, h = 99999999, lh = 70) {
      const { ctx } = this;
      const chr = t.split('');
      let temp = '';
      const row = [];

      // ctx.font = '20px Arial';
      // ctx.fillStyle = 'black';
      // ctx.textBaseline = 'middle';

      for (let a = 0; a < chr.length; a++) {
        if (ctx.measureText(temp).width < w) {
          // 保留
        } else {
          if (row.length + 1 >= h) {
            temp += '...';
            break;
          }
          row.push(temp);
          temp = '';
        }
        temp += chr[a];
      }

      row.push(temp);

      for (let b = 0; b < row.length; b++) {
        ctx.fillText(row[b], x, y + b * lh);
        if (b + 1 >= h) return;
      }
    },
    drawQRCode() {
      return new Promise((resolve, reject) => {
        QRCode.toCanvas(
          this.sharedData.link,
          {
            margin: 3,
            scale: 10,
            errorCorrectionLevel: 'H',
          },
          (error, canvas) => {
            if (error) {
              reject(error);
              return;
            }

            const ctx = canvas.getContext('2d');
            this.$utils.Comm.loadImg(this.$globalVar.shopData.logoUrl).then(
              img => {
                ctx.fillStyle = '#fff';
                ctx.fillRect(
                  canvas.width / 2 - 45,
                  canvas.height / 2 - 45,
                  90,
                  90,
                );
                ctx.drawImage(
                  img,
                  canvas.width / 2 - 40,
                  canvas.height / 2 - 40,
                  80,
                  80,
                );
                resolve(this.QRCodeSrc);
                this.QRCodeSrc = canvas.toDataURL();
              },
            );
          },
        );
      });
    },
    async drawGraphic() {
      if (this.graphicSrc) {
        return Promise.resolve();
      }
      // this.$nprogress.start();
      const { ctx } = this;
      const cvs = ctx.canvas;
      const pa = 40;
      ctx.fillStyle = '#fff';
      ctx.fillRect(0, 0, cvs.width, cvs.height);
      await this.drawQRCode();
      await this.$utils.Comm.loadImgs([
        this.$globalVar.shopData.logoUrl,
        this.good.mainImageUrl,
        this.QRCodeSrc,
        /* ...this.goodsSwiperData.filter((item, index) => {
                    return index < 2;
                }).map((item) => {
                    return (item.imgUrl += '@750w');
                }), */
      ]).then(imgs => {
        let imgH = 0;
        /* logo */
        ctx.drawImage(imgs[0], pa, 0, 160, 160);
        /* 大图 */
        imgH = cvs.width * 0.7;
        ctx.drawImage(imgs[1], (cvs.width - imgH) / 2, 160 + pa, imgH, imgH);
        /* 二维码 */
        const qrcodeWidth = 350;
        ctx.drawImage(
          imgs[2],
          cvs.width - 20 - qrcodeWidth,
          cvs.height - pa * 2 - qrcodeWidth,
          qrcodeWidth,
          qrcodeWidth,
        );
        /* 小图 */
        if (imgs.length > 3) {
          imgs.forEach((item, index) => {
            if (index < 3) return;
            const w = (cvs.width - 40) / 2 - 10;
            const i = index - 3;
            console.log(w, i);
            ctx.drawImage(item, 20 + (w + 20) * i, cvs.width * 0.6 + 140, w, w);
          });
          /* ctx.drawImage(
                        imgs[3],
                        20,
                        cvs.width * 0.6 + 140,
                        (cvs.width - 40) / 2 - 10,
                        cvs.width / 2);
                    ctx.drawImage(
                        imgs[4],
                        cvs.width / 2 + 10,
                        cvs.width * 0.6 + 140,
                        (cvs.width - 40) / 2 - 10,
                        cvs.width / 2);
                    */
        }

        /* 其它 */
        ctx.font = '26px Helvetica';
        ctx.fillStyle = '#999';
        ctx.textAlign = 'center';
        ctx.fillText(
          '长按识别二维码 查看详情',
          cvs.width - qrcodeWidth / 2 - 20,
          cvs.height - 20 - pa,
        );
        // ctx.fillText('查看商品详情', cvs.width - qrcodeWidth / 2 - 20, cvs.height - pa * 2);
        ctx.font = '36px Helvetica';
        ctx.fillStyle = '#333';
        ctx.textAlign = 'left';
        this.drawText(
          this.good.goodsName,
          pa,
          cvs.height - pa - 337,
          cvs.width - qrcodeWidth - pa * 3,
          2,
          60,
        );
        ctx.font = '32px Helvetica';
        ctx.fillStyle = '#868e96';
        this.drawText(
          this.good.goodsSubtitle || '',
          pa,
          cvs.height - pa - 200,
          cvs.width - qrcodeWidth - pa * 3,
          2,
          50,
        );
        ctx.font = '72px Helvetica';
        ctx.fillStyle = '#f60';
        ctx.fillText(
          `${this.currency} ${
            this.isActivityGoods
              ? this.activityGoods.goodsPrice
              : this.good.price
          }`,
          pa,
          cvs.height - pa - 18,
        );
      });
      ctx.save();
      this.graphicSrc = cvs.toDataURL();
      // this.$nprogress.done();
    },
    onItem(action) {
      switch (action) {
        case 'weixin':
          this.sharePopupShow = true;
          break;
        case 'QRCode':
          this.QRCodePopupShow = true;
          break;
        case 'graphic':
          this.graphicPopupShow = true;
          break;
      }
      if (action !== 'weixin') {
        this.$wx.shareLater({
          ...this.sharedData.successData,
          shareUuid: this.sharedData.uuid,
          urllink: this.sharedData.link,
          shareChannel: 3,
        });
      }
    },
    initClipboard() {
      const { copyRecommend } = this.$refs;
      console.log(copyRecommend);
      const copySuccess = () => {
        this.$messageBox.tips('复制成功', 3000);
      };
      const copyError = () => {
        this.$messageBox.tips('复制失败', 3000);
      };
      copyRecommend.forEach(node => {
        const $node = new Clipboard(node);
        $node.on('success', copySuccess);
        $node.on('error', copyError);
      });
    },
  },
  created() {
    this.setShareData({
      ...this.$globalVar.defaultShareData,
      link: `/goods/detail/${this.id}`,
      successData: {
        urllinkType: 1,
        objectId: this.id,
      },
    }).then(data => {
      this.sharedData = data;
      console.log(111, { ...this.sharedData });
    });
  },
  mounted() {
    /*const clipboard = new Clipboard(this.$$copyLink);
        const clipboard2 = new Clipboard(this.$$copyRecommend);
        const copySuccess = () => {
            this.$messageBox.tips('复制成功', 3000);
        };
        const copyError = () => {
            this.$messageBox.tips('复制失败', 3000);
        };
        clipboard.on('success', copySuccess);
        clipboard.on('error', copyError);
        clipboard2.on('success', copySuccess);
        clipboard2.on('error', copyError);*/
  },
  activated() {
    this.getGoodsDetail();
  },
};
</script>

<style lang="scss" scoped>
.cube-toolbar {
  > .item {
    width: 50%;
    > .f-icon {
      width: 2rem;
      height: 2rem;
      font-size: 1rem;
    }
  }
}

.recommend {
  > .copywriting {
    > .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: $padding-small 0;
      border-bottom: 1px solid $color-border;
    }
    > .body {
      padding: $padding-small 0;
    }
  }
}
</style>
<style lang="scss">
.share-graphic {
  padding: 0 $padding;
  > .inner {
    background-color: #fff;
    padding-top: $padding;
    border-radius: 0.05rem;

    > .swiper-container {
      width: 100% !important;
      .swiper-slide {
        > .inner {
          position: relative;
          width: 100%;
          height: 0;
          padding-top: 100%;
          background-color: #fff;
          > img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            width: 100%;
            max-width: 100%;
            max-height: 100%;
          }

          > .loading {
            width: 0.5rem;
            height: 0.5rem;
          }
        }
      }
    }
  }
}
</style>
