<!-- Created by henian.xu on 2017/10/1. -->

<template>
  <Page class="bc-g1">
    <!--<div class="second-header">
            <time-select v-model="statTimeData"></time-select>
        </div>-->
    <container ref="container">
      <div class="list">
        <div
          class="item"
          v-for="(item,index) in orderList"
          :key="index"
        >
          <div class="label">
            {{ item.statDate }}
          </div>
          <div class="details">
            <div>分享佣金合计：<span class="price">{{ item.totalCommisionAmount }}</span></div>
            <div>商品金额合计：<span class="price">{{ item.totalDistributionAmount }}</span></div>
          </div>
        </div>
      </div>

      <infinite-loading
        @infinite="onInfinite"
        ref="infinite"
      >
        <div
          class="ta-c"
          slot="loading"
        >
          <div
            class="nprogress"
            v-if="!pagination.currentPage"
          >
            <div class="spinner-icon">
              <div class="monkey" />
              <div class="cloud01" />
              <div class="cloud02" />
              <div class="shadow" />
            </div>
            <div>加载中...</div>
          </div>
          <div v-else>
            加载中...
          </div>
        </div>
        <div slot="no-result">
          <div class="goods-nothing-icon">
            <i class="f-icon">&#xf03e;</i>
          </div>
          <div class="goods-nothing">
            您还没有相关的账单
          </div>
        </div>
      </infinite-loading>
    </container>
  </Page>
</template>

<script>
import pageMixin from '@/mixins/layout/page';

export default {
  mixins: [pageMixin],
  name: 'MonthlyBill',
  data() {
    return {
      pagination: {},
      // statTimeData: {},
      orderList: [],
    };
  },
  watch: {
    statTimeData() {
      this.getDistributorMonthBillList();
    },
  },
  methods: {
    getDistributorMonthBillList() {
      return this.$api.Buyer.Mb.Distributor.distributorMonthBillList().then(
        json => {
          const result = json.data;
          this.orderList = result.data;
          return Promise.resolve(json);
        },
      );
    },
    // 无限加载
    onInfinite($state) {
      this.getDistributorMonthBillList().then(json => {
        const result = json.data;
        this.pagination = result.pagination;
        const { currentPage, totalRows, totalPages } = this.pagination;
        if (!totalRows || currentPage === totalPages) {
          if (totalRows) {
            $state.loaded();
          }
          $state.complete();
        } else {
          $state.loaded();
        }
      });
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.list {
  padding: 0;
  > .item {
    padding: 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    border-bottom: 1px solid $color-border;
    + .item {
      margin-top: $margin-big;
    }
    > .label {
      padding: 0 $padding;
      font-size: 0.3rem;
      line-height: 0.8rem;
      font-weight: bold;
      background-color: $gray2;
    }
    > .details {
      padding: $padding;
      font-size: 0.26rem;
      line-height: 1.1;
    }
  }
}
</style>
