<!-- Created by henian.xu on 2019/5/30. -->

<template>
  <div
    :class="classes"
    v-bind="linkerProps"
    v-on="$listeners"
  >
    <div class="inner">
      <div class="header">
        <div
          class="avatar"
          :style="`background-image: url('${data.avatar||$defaultImg}');`"
        >
          <div
            v-if="rank"
            :class="['rank',`rank${rank}`]"
          >
            <i
              v-if="rank<=3"
              class="f-icon"
            >&#xf076;</i>
            <div class="inner">
              {{ rank }}
            </div>
          </div>
        </div>
        <div class="inner">
          <div class="name">
            <slot name="badge" />
            {{ data.distributorName }}
          </div>
          <Linker
            v-if="data.distributorTel"
            class="mobile"
            :to="`tel://+${data.distributorCountryCode}${data.distributorTel}`"
          >
            <!--<i class="f-icon">&#xf026;</i>-->+{{ data.distributorCountryCode }} {{ data.distributorTel }}
          </Linker>
        </div>
      </div>
      <div
        v-if="!data.isSaleman"
        class="body"
      >
        <div class="item">
          <div class="extra">
            <div class="price">
              {{ data.totalOrderAmount||0 | price }}
            </div>
            <div class="order">
              订单数:{{ data.totalOrderNum||0 }}
            </div>
          </div>
          <div
            class="help"
            @click.prevent.stop="onHelp(0)"
          >
            <i class="f-icon">&#xf040;</i>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!data.isSaleman && size==='big'"
      class="footer"
    >
      <div class="item">
        <div class="label">
          团队收益
        </div>
        <div class="extra">
          <div class="price">
            {{ data.groupTotalOrderAmount||0 | price }}
          </div>
          <div class="order">
            订单数:{{ data.groupTotalOrderNum||0 }}
          </div>
        </div>
        <div
          class="help"
          @click.prevent.stop="onHelp(1)"
        >
          <i class="f-icon">&#xf040;</i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import linkerMixin from '@/mixins/linker';
export default {
  name: 'SalesmanItem',
  mixins: [linkerMixin],
  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    size: {
      type: String,
      default: '', // small 个人的;big:团队
    },
    rank: {
      type: [String, Number],
      default: '',
    },
  },
  computed: {
    classes() {
      const { size } = this;
      return ['salesman-item', size];
    },
  },
  methods: {
    onHelp(isTeam) {
      this.$messageBox.alert(
        isTeam
          ? '团队收益为从快递点往下2级的所有团队成员的个人收益人群中，跟据团长查询所属下级成员的个人收益合计'
          : '个人收益为自己购买的订单金额',
      );
    },
  },
};
</script>

<style lang="scss">
.salesman-item {
  display: block;
  padding: $padding;
  background-color: #fff;
  border-radius: $padding-small;
  box-shadow: 0 0.04rem 0.12rem -0.06rem #868e96;
  + .salesman-item {
    //border-top: 1px solid $color-border;
    margin-top: $margin;
  }
  > .inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    > .header {
      flex: 1 1 1%;
      line-height: 1.2;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      > .avatar {
        position: relative;
        background: center no-repeat;
        background-size: contain;
        width: 1rem;
        height: 1rem;
        border-radius: 100vw;
        margin-right: $margin-small;
        > .rank {
          position: absolute;
          top: -0.1rem;
          left: -0.1rem;
          display: inline-block;
          > .f-icon {
            font-size: 0.42rem;
          }
          > .inner {
            background-color: $color-yellow;
            display: inline-block;
            height: 1.2em;
            min-width: 1.2em;
            line-height: 1.2;
            text-align: center;
            border-radius: 100vw;
            color: #fff;
          }

          &.rank1,
          &.rank2,
          &.rank3 {
            position: relative;
            .inner {
              font-size: 90%;
              position: absolute;
              //top: 0;
              right: 0;
              bottom: 2px;
              left: 0;
              background-color: transparent;
            }
          }

          &.rank1 {
            > .f-icon {
              color: #ffcc33;
            }
          }
          &.rank2 {
            > .f-icon {
              color: #ff8f0e;
            }
          }
          &.rank3 {
            > .f-icon {
              color: #cc8211;
            }
          }
        }
      }
      > .inner {
        > .name {
          font-size: 110%;
          font-weight: bold;
        }
        > .mobile {
          color: $color-text-minor;
          font-size: 90%;
        }
      }
    }
    > .body {
      flex: 0 0 auto;
      > .item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: $padding-small 0;
        + .item {
          border-top: 1px solid $color-border;
        }
        > .label {
          display: none;
        }
        > .extra {
          white-space: nowrap;
          text-align: right;
          /*display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: baseline;*/
          > .order {
            font-size: 80%;
            color: $color-text-minor;
          }
        }
        > .help {
          flex: 0 0 auto;
          color: $color-red;
          font-size: 120%;
          margin-left: $margin-small;
        }
      }
    }
  }
  > .footer {
    flex: 0 0 auto;
    margin-top: $margin;
    > .item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-top: $padding-small;
      border-top: 1px solid $color-border;
      + .item {
      }
      > .label {
        flex: 1 1 1%;
      }
      > .extra {
        flex: 0 0 auto;
        white-space: nowrap;
        text-align: right;
        > .order {
          font-size: 80%;
          color: $color-text-minor;
        }
      }
      > .help {
        flex: 0 0 auto;
        color: $color-red;
        font-size: 120%;
        margin-left: $margin-small;
      }
    }
  }
}
</style>
