<!-- Created by henian.xu on 2018/12/27. -->

<template>
  <Page v-if="!$globalVar.device.isMobile">
    <Container ref="container">
      <div class="ta-c">
        <h3 class="ma-b">
          操作说明
        </h3>
        <div>请先点击【模板下载】按钮将模板下至本地，<br>然后根据模板内容，填写要导入的订单信息。</div>
      </div>

      <div class="ma-t">
        <upload
          ref="upload"
          class="upload"
          action="/rpc/buyer/ex/order/importOrderExcel"
          accept=".xls,.xlsx,.csv"
          :column="2"
          :max-files="1"
          v-model="files"
        />
      </div>
      <div
        v-if="files&&files.length"
        class="ma-t ta-c"
      >
        <h3>上传结果</h3>
        <div
          v-for="(item,index) in files"
          :key="index"
          v-html="item.msg || item.error"
        />
      </div>
    </Container>
    <PageBar>
      <Button
        label="模板下载"
        theme="second"
        @click="onDown"
      />
      <!--<Button
                label="上传excl"
                theme="main"
                @click="onUpload"
            />-->
    </PageBar>
  </Page>
  <Page v-else>
    <Container ref="container">
      <div class="ta-c">
        导入订单功能只能在电脑端使用。
      </div>
    </Container>
  </Page>
</template>

<script>
import pageMixin from '@/mixins/layout/page';
export default {
  name: 'OrderImport',
  mixins: [pageMixin],
  data() {
    return {
      files: [],
    };
  },
  methods: {
    onUpload() {
      this.$refs['upload'].selectFile();
    },
    onDown() {
      this.$http.download('/rpc/buyer/ex/order/downBatchOrderExcel');
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: $padding-big;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  .upload {
    justify-content: center;
  }
}
</style>
