<!-- Created by henian.xu on 2017/10/1. -->

<template>
  <Page class="bc-g1">
    <container ref="container">
      <div class="page-subtitle">
        <span>待完成</span>
      </div>
      <div class="tool-bar">
        <div class="item">
          <div class="num">
            {{ distributor.waitCompleteOrderCount || 0 }}
          </div>
          <div class="label">
            订单数
          </div>
        </div>
        <div class="item">
          <div class="num price tc-base">
            {{ distributor.waitCompleteDistributionAmount || 0 }}
          </div>
          <div class="label">
            分享金额
          </div>
        </div>
        <div class="item">
          <div class="num price tc-base">
            {{ distributor.waitCompleteCommisionAmount || 0 }}
          </div>
          <div class="label">
            提成金额
          </div>
        </div>
      </div>
      <div class="page-subtitle">
        <span>已完成</span>
      </div>
      <div class="tool-bar">
        <div class="item">
          <div class="num tc-main">
            {{ distributor.completeOrderCount || 0 }}
          </div>
          <div class="label tc-main">
            订单数
          </div>
        </div>
        <div class="item">
          <div class="num price tc-main">
            {{ distributor.completeDistributionAmount || 0 }}
          </div>
          <div class="label tc-main">
            分享金额
          </div>
        </div>
        <div class="item">
          <div class="num price tc-main">
            {{ distributor.completeCommisionAmount || 0 }}
          </div>
          <div class="label tc-main">
            提成金额
          </div>
        </div>
      </div>
      <div class="page-subtitle">
        <span>已取消</span>
      </div>
      <div class="tool-bar">
        <div class="item">
          <div class="num tc-red">
            {{ distributor.cancelOrderCount || 0 }}
          </div>
          <div class="label tc-red">
            订单数
          </div>
        </div>
        <div class="item">
          <div class="num price tc-red">
            {{ distributor.cancelDistributionAmount || 0 }}
          </div>
          <div class="label tc-red">
            分享金额
          </div>
        </div>
        <div class="item">
          <div class="num price tc-red">
            {{ distributor.cancelCommisionAmount || 0 }}
          </div>
          <div class="label tc-red">
            提成金额
          </div>
        </div>
      </div>

      <!--<div class="list">
                <div class="item">
                    <div class="label">待完成订单数</div>
                    <div class="details">{{distributor.waitCompleteOrderCount}}</div>
                </div>
                <div class="item">
                    <div class="label">待完成分享金额</div>
                    <div class="details"><div class="price fs-normal">{{distributor.waitCompleteDistributionAmount | price}}</div></div>
                </div>
                <div class="item">
                    <div class="label">待完成提成金额</div>
                    <div class="details"><div class="price fs-normal">{{distributor.waitCompleteCommisionAmount | price}}</div></div>
                </div>
            </div>
            <div class="list">
                <div class="item">
                    <div class="label tc-main">已完成订单数</div>
                    <div class="details">{{distributor.completeOrderCount}}</div>
                </div>
                <div class="item">
                    <div class="label tc-main">已完成分享金额</div>
                    <div class="details"><div class="price fs-normal">{{distributor.completeDistributionAmount | price}}</div></div>
                </div>
                <div class="item">
                    <div class="label tc-main">已完成提成金额</div>
                    <div class="details"><div class="price fs-normal">{{distributor.completeCommisionAmount | price}}</div></div>
                </div>
            </div>
            <div class="list">
                <div class="item">
                    <div class="label tc-red">已取消订单数</div>
                    <div class="details">{{distributor.cancelOrderCount}}</div>
                </div>
                <div class="item">
                    <div class="label tc-red">已取消分享金额</div>
                    <div class="details"><div class="price fs-normal">{{distributor.cancelDistributionAmount | price}}</div></div>
                </div>
                <div class="item">
                    <div class="label tc-red">已取消提成金额</div>
                    <div class="details"><div class="price fs-normal">{{distributor.cancelCommisionAmount | price}}</div></div>
                </div>
            </div>-->
    </container>
  </Page>
</template>

<script>
import pageMixin from '@/mixins/layout/page';

export default {
  name: 'OrderSurvey',
  mixins: [pageMixin],
  data() {
    return {
      distributor: {},
    };
  },
  methods: {
    getDistributorDetail() {
      this.$api.Buyer.Mb.Distributor.distributorDetail().then(json => {
        const result = json.data;
        this.distributor = result.data;
      });
    },
  },
  created() {
    this.getDistributorDetail();
  },
};
</script>

<style>
</style>
