<!-- Created by henian.xu on 2018/12/27. -->

<template>
  <Page>
    <Container ref="container">
      <List>
        <DistributorCard
          v-for="item in cardList"
          :key="item.id"
          :data="item"
        />
      </List>
    </Container>
  </Page>
</template>

<script>
import pageMixin from '@/mixins/layout/page';
export default {
  name: 'CardList',
  mixins: [pageMixin],
  data() {
    return {
      cardList: [],
    };
  },
  methods: {
    getListData() {
      return this.$api.Buyer.Mb.Distributor.distributorLevelAgentList().then(
        json => {
          this.cardList = json.data.data;
        },
      );
    },
  },
  created() {
    this.getListData();
  },
};
</script>

<style lang="scss">
</style>
