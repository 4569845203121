import { render, staticRenderFns } from "./platformRegister.vue?vue&type=template&id=29858410&scoped=true&"
import script from "./platformRegister.vue?vue&type=script&lang=js&"
export * from "./platformRegister.vue?vue&type=script&lang=js&"
import style0 from "./platformRegister.vue?vue&type=style&index=0&id=29858410&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29858410",
  null
  
)

export default component.exports