<!-- Created by henian.xu on 2018/12/27. -->

<template>
  <Page>
    <Container ref="container">
      <List>
        <div
          v-for="(item,index) in listData"
          class="coupon-state-item"
          :key="index"
        >
          <div class="label">
            <div class="name">
              {{ item.activityName }}
            </div>
            <div class="time">
              {{ item.insertTime|moment }}
            </div>
          </div>
          <div class="desc">
            {{ item.activityDesc }}
          </div>
          <div class="content">
            <div class="item">
              <div class="label">
                发放量:
              </div>
              <div class="value">
                {{ item.couponGiveQuantity }}
              </div>
            </div>
            <div class="item">
              <div class="label">
                领取量:
              </div>
              <div class="value">
                {{ item.couponDrawQuantity }}
              </div>
            </div>
            <div class="item">
              <div class="label">
                使用量:
              </div>
              <div class="value">
                {{ item.couponUseQuantity }}
              </div>
            </div>
          </div>
        </div>
      </List>
    </Container>
  </Page>
</template>

<script>
import pageMixin from '@/mixins/layout/page';
export default {
  name: 'CardList',
  mixins: [pageMixin],
  data() {
    return {
      listData: [],
    };
  },
  methods: {
    getListData() {
      return this.$api.Buyer.Mb.Distributor.couponActivityStatList().then(
        json => {
          this.listData = json.data.data;
        },
      );
    },
  },
  created() {
    this.getListData();
  },
};
</script>

<style lang="scss">
.coupon-state-item {
  padding: $padding-small $padding;
  background-color: #fff;
  + .coupon-state-item {
    margin-top: $margin;
  }

  > .label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    > .name {
      flex: 1 1 1%;
      font-weight: bold;
    }
    > .time {
      flex: 0 0 auto;
      color: $color-text-minor;
    }
  }
  > .desc {
    color: $color-text-minor;
  }

  > .content {
    > .item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: $padding-small 0;
      + .item {
        border-top: 1px solid $color-border;
      }
      > .value {
        color: $color-main;
      }
    }
  }
}
</style>
