<!-- Created by henian.xu on 2018/12/27. -->

<template>
  <Page>
    <SalesmanItem
      v-if="sign === 'team' && referenceId && salesmanStat && salesmanStat.id"
      class="ma-b"
      :data="salesmanStat"
      size="big"
    >
      <span
        slot="badge"
        class="badge badge-main fs-small"
      >{{ salesmanStat.isSaleman?'业务员':'团长' }}</span>
    </SalesmanItem>
    <div
      v-else
      class="second-header"
    >
      <div
        :class="['period',{active:inCurrentWeek}]"
        @click="onWeeks"
      >
        <div class="inner">
          本周
        </div>
      </div>
      <div
        :class="['period',{active:inCurrentMonth}]"
        @click="onMonth"
      >
        <div class="inner">
          本月
        </div>
      </div>
      <time-select
        v-model="statTimeDate"
        :all-time="allTime"
        format="YYYY-MM-DD"
        @change="onTimeSelectChange"
      />
    </div>
    <Container ref="container">
      <List
        v-if="salesmanStatList && salesmanStatList.length"
        class="pa-a"
      >
        <SalesmanItem
          v-for="(item,index) in salesmanStatList"
          :key="item.id"
          :data="item"
          :rank="index+1"
          :size="sign === 'team'?'big':''"
          :to="`/distributor/salesman/${sign}?referenceId=${item.id}`"
        >
          <span
            v-if="sign === 'team'"
            slot="badge"
            class="badge badge-second fs-small"
          >{{ item.isSaleman?'业务员':'成员' }}</span>
        </SalesmanItem>
      </List>
      <div
        v-else
        class="pa-a ta-c tc-g5"
      >
        无成员
      </div>
    </Container>
  </Page>
</template>

<script>
import pageMixin from '@/mixins/layout/page';
import SalesmanItem from './_salesmanItem';
export default {
  name: 'CardList',
  components: { SalesmanItem },
  mixins: [pageMixin],
  data() {
    return {
      statData: {},
      statTimeDate: {},
      allTime: false,
      currentWeek: {
        beginDate: this.$moment()
          .startOf('week')
          .add(1, 'days')
          .format('YYYY-MM-DD'),
        endDate: this.$moment()
          .endOf('week')
          .add(1, 'days')
          .format('YYYY-MM-DD'),
      },
      currentMonth: {
        beginDate: this.$moment()
          .startOf('month')
          .format('YYYY-MM-DD'),
        endDate: this.$moment()
          .endOf('month')
          .format('YYYY-MM-DD'),
      },
    };
  },
  computed: {
    sign() {
      return this.$route.params.sign + '';
    },
    referenceId() {
      return this.$route.query.referenceId || 0;
    },
    salesmanStat() {
      const { salesmanStat } = this.statData || {};
      return {
        // distributorName: '销售统计汇总',
        // distributorTel: '',
        ...salesmanStat,
      };
    },
    salesmanStatList() {
      return this.statData.salesmanStatList || [];
    },
    inCurrentWeek() {
      const { currentWeek, statTimeDate } = this;
      return (
        currentWeek.beginDate === statTimeDate.beginDate &&
        currentWeek.endDate === statTimeDate.endDate
      );
    },
    inCurrentMonth() {
      const { currentMonth, statTimeDate } = this;
      return (
        currentMonth.beginDate === statTimeDate.beginDate &&
        currentMonth.endDate === statTimeDate.endDate
      );
    },
  },
  methods: {
    getListData() {
      const { sign, referenceId, statTimeDate } = this;
      const { beginDate: statBeginDate, endDate: statEndDate } = statTimeDate;
      const data = {
        statBeginDate,
        statEndDate,
      };
      if (sign === 'team') {
        data.referenceId = referenceId;
      }
      return this.$api.Buyer.Mb.Distributor.salesmanStat(data).then(json => {
        this.statData = json.data.data || {};
      });
    },
    onTabsChange(item) {
      this.infiniteDisabled = true;
      this.dataList = [];
      this.pagination = {};
      this.$nextTick(() => {
        this.$router.replace(
          `/distributor/salesman/${item.sign}`,
          () => {},
          () => {
            this.infiniteDisabled = false;
          },
        );
      });
    },
    onTimeSelectChange() {
      console.log('onTimeSelectChange');
      this.$nextTick(() => {
        this.getListData();
      });
    },
    onWeeks() {
      console.log('onWeeks');
      this.statTimeDate = this.currentWeek;
      this.onTimeSelectChange();
    },
    onMonth() {
      console.log('onMonth');
      this.statTimeDate = this.currentMonth;
      this.onTimeSelectChange();
    },
  },
  created() {
    // this.getListData();
  },
};
</script>

<style lang="scss" scoped>
.second-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  > .period {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: $padding-small;
    background-color: #fff;
    > .inner {
      font-size: 0.8 * 100%;
      padding: $padding-small;
      border-radius: $padding-small;
      border: 1px solid $color-border;
    }

    &.active {
      > .inner {
        background-color: $color-main;
        border-color: $color-main;
        color: #fff;
      }
    }
  }
  > .time-select {
    flex: 1 1 1%;
  }
}
</style>
