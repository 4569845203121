<!-- Created by henian.xu on 2018/3/19. -->

<template>
  <div class="shares-box">
    <transition name="show">
      <div
        class="popup shares-box"
        ref="popup"
        v-show="popupShow"
        @click.self="close"
      >
        <transition name="popup-down">
          <div
            class="inner"
            v-show="popupShow"
          >
            <div class="header">
              <!--<div class="label">每份最多可取佣金 <span class="price">{{ commisionPrice | price }}</span></div>-->
              <div class="label">
                每份最多可赚取佣金 <span class="price">{{ commisionPrice | price }}</span>
              </div>
            </div>
            <div class="body">
              <div class="desc">
                <p>1，当顾客通过您分享的商品链接成功下单后，您可获取相应的佣金。佣金处于冻结状态。</p>
                <p class="ma-t">
                  2，当顾客确认收货后 7 天，冻结的佣金会自动解冻，转到您的账户，可在个人中心查看。
                </p>
              </div>
              <cube-toolbar>
                <div
                  class="item"
                  @click="onItem('weixin')"
                >
                  <i class="f-icon bc-green tc-w">&#xf080;</i>
                  <div class="label">
                    微信
                  </div>
                </div>
                <div
                  class="item"
                  @click="onItem('copyLink')"
                >
                  <button
                    ref="copyLink"
                    class="f-icon bc-g6 tc-w"
                    :data-clipboard-text="shareLink"
                  >
                    &#xf081;
                  </button>
                  <div class="label">
                    复制链接
                  </div>
                </div>
                <div
                  class="item"
                  @click="onItem('QRCode')"
                >
                  <i class="f-icon bc-second tc-w">&#xf02b;</i>
                  <div class="label">
                    二维码
                  </div>
                </div>
                <div
                  class="item"
                  @click="onItem('graphic')"
                >
                  <i class="f-icon bc-blue tc-w">&#xf083;</i>
                  <div class="label">
                    图文二维码
                  </div>
                </div>
              </cube-toolbar>
            </div>
            <div class="footer">
              <linker
                to="/distributor/home"
                class="btn btn-main"
              >
                分销商个人中心
              </linker>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import Clipboard from 'clipboard';
import popupMixin from '@/mixins/popup';

export default {
  name: 'SharesBox',
  mixins: [popupMixin],
  data() {
    return {
      clipboard: null,
    };
  },
  props: {
    shareLink: {
      type: String,
      default: window.location.href,
    },
    commisionPrice: {
      type: [Number, String],
      default: '',
    },
  },
  computed: {
    $$copyLink() {
      return this.$refs.copyLink;
    },
  },
  methods: {
    onItem(action) {
      this.done().then(() => {
        this.$emit('onItem', action);
      });
    },
    open() {
      this.popupShow = true;
    },
    close() {
      this.popupShow = false;
    },
  },
  mounted() {
    this.clipboard = new Clipboard(this.$$copyLink);
    this.clipboard.on('success', () => {
      this.$nextTick(() => {
        this.$messageBox.tips('复制成功', 3000);
      });
    });
    this.clipboard.on('error', () => {
      this.$nextTick(() => {
        this.$messageBox.tips('复制失败', 3000);
      });
    });
  },
};
</script>

<style lang="scss">
.popup-wrap {
  .shares-box.popup {
    justify-content: flex-end;
    > .inner {
      background-color: #fff;
      max-width: inherit;
      border-radius: 0;
      width: 100%;
    }
  }
}
</style>
