<!-- Created by henian.xu on 2018/12/14. -->

<template>
  <div
    class="goods-category"
    v-if="catList && catList.length>1"
  >
    <div
      :class="['item',{active:item.id===model}]"
      v-for="item in catList"
      :key="item.id"
      @click="onItem(item)"
    >
      <div class="label">
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'GoodsCategory',
  data() {
    return {};
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    activityType: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters('goods', { catList_: 'catList' }),
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    catList() {
      const { catList_, activityType } = this;
      if (!catList_(activityType)) {
        this.getCatList({ activityType });
        return null;
      }
      return catList_(activityType);
    },
    initInput() {
      const { model, catList } = this;
      const b = !model && catList && catList.length;
      return b ? catList[0].id : 0;
    },
  },
  watch: {
    initInput: {
      handler(val) {
        if (!val) return;
        this.model = val;
      },
      immediate: true,
    },
    catList: {
      handler(val) {
        if (val && !val.length) {
          console.log('getCatList', val);
          this.$emit('nothing');
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('goods', ['getCatList']),
    onItem(item) {
      this.model = item.id;
    },
  },
};
</script>

<style lang="scss">
.goods-category {
  flex: 0 0 auto;
  background-color: $gray2;
  overflow-y: auto;
  overflow-x: hidden;
  > .item {
    padding: $padding;
    background-color: transparent;
    &.active {
      background-color: #fff;
    }
    max-width: 6em;
  }
}
</style>
