<!-- Created by henian.xu on 2019/1/2. -->

<template>
  <Page>
    <div class="second-header">
      <ListItem
        class="reverse bor-b"
        extra="选择月份"
      >
        <div
          class="label"
          slot="label"
        >
          <Datetime
            class="ta-r"
            format="YYYY-MM"
            clear-text="全部"
            @on-clear="onDateTimeClear"
            @input="onDateInput"
            v-model="datetime"
          >
            {{ datetime||'全部' }}
          </Datetime>
        </div>
      </ListItem>
      <Tabs
        average
        @tabChange="onTabsChange"
      >
        <TabPanel
          v-if="$hasPower(['distributorIncomeMode:eachLevelIncome']) && $globalVar.userData.distributionIncomeMode & 4"
          :active-panel="sign === 'sell'"
          sign="sell"
          label="团队收益"
        />
        <TabPanel
          v-if="$hasPower(['distributorIncomeMode:recommendIncome']) && $globalVar.shopData.distributorRegisterMode === 2"
          :active-panel="sign === 'recommend'"
          sign="recommend"
          label="推荐提成"
        />
        <TabPanel
          v-if="$hasPower(['distributorIncomeMode:shareIncome']) && $globalVar.userData.distributionIncomeMode & 1"
          :active-panel="sign === 'share'"
          sign="share"
          label="分享返现"
        />
        <TabPanel
          v-if="$hasPower(['distributorIncomeMode:storeEachLevelIncome']) && $globalVar.userData.distributionIncomeMode & 8"
          :active-panel="sign === 'store'"
          sign="store"
          label="门店层级提成"
        />
      </Tabs>
    </div>
    <Container ref="container">
      <List v-show="sign==='recommend'">
        <PercentageItem
          v-for="(item,index) in recommendIncomeList"
          :key="index"
          :data="item"
        />
      </List>
      <List v-show="sign==='sell'">
        <PercentageItem
          v-for="(item,index) in eachLevelIncomeList"
          :key="index"
          :data="item"
        />
      </List>
      <List v-show="sign==='share'">
        <PercentageItem
          v-for="(item,index) in shareIncomeList"
          :key="index"
          :data="item"
        />
      </List>
      <List v-show="sign==='store'">
        <PercentageItem
          v-for="(item,index) in storeEachLevelIncomeList"
          :key="index"
          :data="item"
        />
      </List>
    </Container>
  </Page>
</template>

<script>
import { Buyer } from '@/api';
import pageMixin from '@/mixins/layout/page';
import PercentageItem from './_percentageItem';
export default {
  name: 'PercentageIndex',
  mixins: [pageMixin],
  components: { PercentageItem },
  data() {
    return {
      datetime: '',
      eachLevelIncomeList: [],
      shareIncomeList: [],
      recommendIncomeList: [],
      storeEachLevelIncomeList: [],
    };
  },
  computed: {
    sign() {
      return this.$route.params.sign + '';
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.datetime = to.query.month || '';
    });
  },
  methods: {
    onDateTimeClear() {
      this.datetime = '';
    },
    onDateInput() {
      this.getDataDetail();
    },
    onTabsChange(item) {
      this.$router.replace(`/distributor/percentage/${item.sign}`);
    },
    numberToChinese(value) {
      const map = {
        '1': '一',
        '2': '二',
        '3': '三',
        '4': '四',
        '5': '五',
        '6': '六',
        '7': '七',
        '8': '八',
        '9': '九',
        '0': '〇',
      };
      value = value + '';
      return value
        .split('')
        .map(item => map[item])
        .join('');
    },
    getDataDetail() {
      this.eachLevelIncomeList = [];
      this.recommendIncomeList = [];
      this.storeEachLevelIncomeList = [];
      return Buyer.Mb.Distributor.percentageStatDetail({
        statMonth: this.datetime,
      }).then(json => {
        const res = {
          eachLevelIncomeList: [],
          shareIncomeList: [],
          recommendIncomeList: [],
          storeEachLevelIncomeList: [],
          ...json.data.data,
        };
        this.eachLevelIncomeList = res.eachLevelIncomeList.map(item => {
          return {
            ...item,
            distributorLevelName: `直属${this.numberToChinese(
              item.distributorLevel,
            )}级提成`,
            list: [
              {
                name: '订单数',
                value: item.totalOrderNum || 0,
              },
              {
                name: '总销售额',
                isPrice: true,
                value: item.totalOrderAmount || 0,
              },
              {
                name: '总提成金额',
                isPrice: true,
                value: item.totalCommisionAmount || 0,
              },
            ],
          };
        });
        this.shareIncomeList = res.shareIncomeList.map(item => {
          return {
            ...item,
            // distributorLevelName: `直属${this.numberToChinese(item.distributorLevel)}级提成`,
            list: [
              {
                name: '订单数',
                value: item.orderCount || 0,
              },
              {
                name: '总销售额',
                isPrice: true,
                value: item.totalOrderAmount || 0,
              },
              {
                name: '总提成金额',
                isPrice: true,
                value: item.completeCommisionAmount || 0,
              },
            ],
          };
        });
        this.recommendIncomeList = res.recommendIncomeList.map(item => {
          return {
            ...item,
            distributorLevelName:
              item.levelNum - 1
                ? `直属${this.numberToChinese(item.levelNum - 1)}级提成`
                : '直接推荐提成',
            list: item.list
              .map(node => {
                return [
                  {
                    ...node,
                    name: `${node.levelName}人数`,
                    value: node.totalNum,
                  },
                  {
                    ...node,
                    name: `${node.levelName}金额`,
                    isPrice: true,
                    value: node.totalAmount,
                  },
                ];
              })
              .reduce((p, c) => p.concat(c), []),
          };
        });
        this.storeEachLevelIncomeList = res.storeEachLevelIncomeList.map(
          item => {
            return {
              ...item,
              distributorLevelName: `直属${this.numberToChinese(
                item.distributorLevel,
              )}级提成`,
              list: [
                {
                  name: '订单数',
                  value: item.totalOrderNum || 0,
                },
                {
                  name: '总销售额',
                  isPrice: true,
                  value: item.totalOrderAmount || 0,
                },
                {
                  name: '总提成金额',
                  isPrice: true,
                  value: item.totalCommisionAmount || 0,
                },
              ],
            };
          },
        );
      });
    },
  },
  created() {
    // this.getDataDetail();
  },
};
</script>

<style lang="scss">
</style>
