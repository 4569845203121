<!-- Created by henian.xu on 2019/7/4. -->

<template>
  <Page>
    <Container ref="container">
      <i
        class="f-icon tc-g3"
        style="font-size: 2.5rem;"
      >&#xf089;</i>
      <div class="ta-c pa-a ma-t">
        请联系平台管理员开通该板块权限。
      </div>
    </Container>
  </Page>
</template>

<script>
export default {
  name: 'PlatformRegister',
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.container {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $gray6;
}
</style>
