<!-- Created by henian.xu on 2017/10/1. -->

<template>
  <Page class="bc-g1">
    <Container ref="container">
      <DistributorUserBox :data="distributor" />
      <!--<div class="money-box">
                <div class="level">
                    <img
                        :src="distributor.levelIconUrl"
                        class="level-icon">
                    &lt;!&ndash;<div class="badge badge-second fs-small ma-t">{{ distributor.levelName }}</div>&ndash;&gt;
                    <linker
                        v-if="distributor.upgradeDistributorLevel"
                        class="badge badge-main fs-small ma-t"
                        to="/distributor/cardList">权益升级</linker>
                    <div class="recommend-code">邀请码: <span>{{ distributor.invitationCode }}</span></div>
                </div>
                &lt;!&ndash;<div class="label tc-base">累计收入</div>
                <linker
                    class="money"
                    to="/distributor/monthlyBill"
                >{{ distributor.totalIncome | money }}
                </linker>&ndash;&gt;
            </div>-->

      <Panel
        class="ma-bor-b"
        to="/distributor/collect"
        label="预期提成"
      >
        <div
          class="extra"
          slot="extra"
        >
          查看详情 <div class="f-icon fs-small">
            &#xf012;
          </div>
        </div>
        <div class="tool-bar">
          <div class="item">
            <div class="num price">
              {{ (distributor.totalIncome + distributor.frozenAmount)||0 | price }}
            </div>
            <div class="label">
              累计提成
            </div>
          </div>
          <div class="item">
            <div class="num price">
              {{ distributor.frozenAmount||0 | price }}
            </div>
            <div class="label">
              冻结金额
            </div>
          </div>
          <div
            class="item"
          >
            <div class="num price">
              {{ ((distributor.totalIncome - distributor.cashedAmount - distributor.cashingAmount)||0) | price }}
            </div>
            <div class="label">
              账户金额
            </div>
          </div>
        </div>
        <!--<div class="expect-wrap">
                    <div class="price fs-super">
                        {{ distributor.totalIncome + distributor.frozenAmount | price }}
                    </div>
                    <div class="ma-l">
                        账户余额: <span class="price">{{ distributor.totalIncome - distributor.cashedAmount - distributor.cashingAmount | price }}</span>
                    </div>
                </div>-->
      </Panel>

      <!--<div class="tool-bar nma-b bor-b">
                <linker
                    to="/distributor/orderList/100"
                    class="item">
                    <div class="num tc-price">{{ distributor.monthCommisionAmount || 0 }}</div>
                    <div class="label">
                        本月收入
                    </div>
                </linker>
                <linker
                    to="/distributor/orderSurvey"
                    class="item">
                    <div class="num tc-price">{{ distributor.orderCount || 0 }}</div>
                    <div class="label">
                        总订单数
                    </div>
                </linker>
            </div>
            <div class="tool-bar">
                &lt;!&ndash;<linker to="/integralDetails" class="item">
                    <div class="num tc-greed">{{distributor.commisionRate || 0}}%</div>
                    <div class="label">
                        抽成比例
                    </div>
                </linker>&ndash;&gt;
                <linker
                    to="/distributor/orderList/100?allTime=1"
                    class="item">
                    <div class="num tc-main">{{ distributor.completeOrderCount || 0 }}</div>
                    <div class="label">
                        完成订单数
                    </div>
                </linker>
                <linker
                    to="/distributor/orderList/10?allTime=1"
                    class="item">
                    <div class="num">{{ distributor.waitCompleteOrderCount || 0 }}</div>
                    <div class="label">
                        未完成订单数
                    </div>
                </linker>
                <linker
                    to="/distributor/orderList/99?allTime=1"
                    class="item">
                    <div class="num tc-red">{{ distributor.cancelOrderCount || 0 }}</div>
                    <div class="label">
                        已取消订单数
                    </div>
                </linker>
            </div>-->

      <list>
        <list-item
          v-if="$hasPower(['distributorMenu:percentageStat:use'])"
          class="link"
          icon-class-name="tc-second"
          icon="&#xf068;"
          @click="onPercentage"
        >
          <div
            class="label lh-normal"
            slot="label"
          >
            <div>我的提成</div>
            <div class="fs-small tc-g6">
              查看各提成信息内容
            </div>
          </div>
        </list-item>
        <list-item
          v-if="$hasPower(['distributorMenu:distributorQrcode:use'])"
          class="link"
          icon-class-name="tc-blue"
          icon="&#xf02b;"
          @click="()=>{onSpreadQrcode()}"
        >
          <div
            class="label lh-normal"
            slot="label"
          >
            <div>推广二维码</div>
            <div class="fs-small tc-g6">
              发展团队人员
            </div>
          </div>
        </list-item>
        <!--        <list-item
          v-if="$hasPower(['distributorMenu:SubordinateList:use'])"
          to="/distributor/team/list"
          class="link"
          icon-class-name="tc-green"
          icon="&#xf022;"
        >
          <div
            class="label lh-normal"
            slot="label"
          >
            <div>我的团队</div>
            <div class="fs-small tc-g6">
              查看发展人员信息
            </div>
          </div>
        </list-item>-->
        <!--<list-item
                    to="/distributor/ranking"
                    class="link"
                    icon-class-name="tc-second"
                    icon="&#xf075;"
                >
                    <div
                        class="label lh-normal"
                        slot="label">
                        <div>排行榜</div>
                        <div class="fs-small tc-g6">分销商按日、月、年总分享金额排名</div>
                    </div>
                </list-item>-->
        <list-item
          v-if="$hasPower(['distributorMenu:userMessageList:use'])"
          to="/distributor/team/log"
          class="link"
          icon-class-name="tc-blue"
          icon="&#xf067;"
        >
          <div
            class="label lh-normal"
            slot="label"
          >
            <div>返现日志</div>
            <div class="fs-small tc-g6">
              查看系统返现日志信息
            </div>
          </div>
        </list-item>
        <list-item
          v-if="$hasPower(['distributorMenu:bestowList:use'])"
          to="/distributor/bestow"
          class="link"
          icon-class-name="tc-second"
          icon="&#xf00d;"
        >
          <div
            class="label lh-normal"
            slot="label"
          >
            <div>优惠码</div>
            <div class="fs-small tc-g6">
              查看优惠码及使用信息
            </div>
          </div>
        </list-item>
        <list-item
          v-if="isSalesman"
          to="/distributor/salesman/personal"
          class="link"
          icon-class-name="tc-red"
          icon="&#xf05c;"
        >
          <div
            class="label lh-normal"
            slot="label"
          >
            <div>业务员销售统计</div>
            <div class="fs-small tc-g6">
              查看业务员销售统计
            </div>
          </div>
        </list-item>
        <list-item
          v-if="$hasPower(['distributorMenu:contactCompany:use'])"
          to="/distributor/contact"
          class="link"
          icon-class-name="tc-main"
          icon="&#xf024;"
        >
          <div
            class="label lh-normal"
            slot="label"
          >
            <div>联系公司</div>
            <div class="fs-small tc-g6">
              查看联系我们相关的信息
            </div>
          </div>
        </list-item>
        <list-item
          v-if="$hasPower(['distributorMenu:separationRules:use'])"
          :to="`/distributor/cardDetail/${distributor.distributorLevelId}?pageTitle=分润规则&noBuy`"
          class="link"
          icon-class-name="tc-blue"
          icon="&#xf066;"
        >
          <div
            class="label lh-normal"
            slot="label"
          >
            <div>分润规则</div>
            <div class="fs-small tc-g6">
              查看分润规则的信息
            </div>
          </div>
        </list-item>
        <!--<list-item
                    v-if="$globalVar.userData.shopStoreType===2"
                    :to="`/proxyStore/${$globalVar.userData.storeUserName}/home`"
                    class="link"
                    icon-class-name="tc-main"
                    icon="&#xf089;"
                >
                    <div
                        class="label lh-normal"
                        slot="label"
                    >
                        <div>店铺管理</div>
                        <div class="fs-small tc-g6">
                            管理一键代发店铺信息
                        </div>
                    </div>
                </list-item>-->
        <list-item
          v-if="$hasPower(['distributorIncomeMode:shareIncome']) && $hasPower(['distributorMenu:buyerVisitLogStat:use']) && $globalVar.userData.distributionIncomeMode & 1"
          to="/distributor/visitLogDetail"
          class="link"
          icon-class-name="tc-main"
          icon="&#xf06d;"
        >
          <div
            class="label lh-normal"
            slot="label"
          >
            <div>流量统计</div>
            <div class="fs-small tc-g6">
              查看分享赚的订单转化率
            </div>
          </div>
        </list-item>
        <list-item
          v-if="$hasPower(['distributorMenu:couponActivityStat:use'])"
          to="/distributor/couponStat"
          class="link"
          icon-class-name="tc-second"
          icon="&#xf03d;"
        >
          <div
            class="label lh-normal"
            slot="label"
          >
            <div>优惠券活动统计</div>
            <div class="fs-small tc-g6">
              查看分享赚的订单转化率
            </div>
          </div>
        </list-item>
        <!--        <list-item
          :to="`/distributor/orderImport`"
          class="link"
          icon-class-name="tc-green"
          icon="&#xf00b;"
        >
          <div
            class="label lh-normal"
            slot="label"
          >
            <div>批量上传订单</div>
            <div class="fs-small tc-g6">
              通过填写excel中的订单信息，快速上传订单
            </div>
          </div>
        </list-item>-->
        <list-item
          to="/distributor/orderList/10"
          class="link"
          icon-class-name="tc-main"
          icon="&#xf061;"
        >
          <div
            class="label lh-normal"
            slot="label"
          >
            <div>分享订单</div>
            <div class="fs-small tc-g6">
              所有分享订单信息
            </div>
          </div>
        </list-item>
        <!--        <list-item
          to="/distributor/monthlyBill"
          class="link"
          icon-class-name="tc-blue"
          icon="&#xf062;"
        >
          <div
            class="label lh-normal"
            slot="label"
          >
            <div>月度账单</div>
            <div class="fs-small tc-g6">
              历史月份分享金额信息
            </div>
          </div>
        </list-item>-->
      </list>

      <canvas
        class="canvas"
        v-show="false"
        ref="graphicCanvas"
        width="500"
        height="600"
      />
    </Container>
  </Page>
</template>

<script>
import { Buyer } from '@/api/index';
import pageMixin from '@/mixins/layout/page';
import DistributorUserBox from './_userBoxDistributor';

export default {
  name: 'Index',
  mixins: [pageMixin],
  components: { DistributorUserBox },
  data() {
    return {
      distributor: {},
      QRCodeSrc: '',
    };
  },
  /*beforeRouteEnter(to, from, next) {
    },*/
  methods: {
    onPercentage() {
      if (
        this.$hasPower(['distributorIncomeMode:recommendIncome']) &&
        this.$globalVar.userData.distributionIncomeMode & 2
      ) {
        this.$router.push('/distributor/percentage/recommend');
      } else if (
        this.$hasPower(['distributorIncomeMode:eachLevelIncome']) &&
        this.$globalVar.userData.distributionIncomeMode & 4
      ) {
        this.$router.push('/distributor/percentage/sell');
      } else if (
        this.$hasPower(['distributorIncomeMode:shareIncome']) &&
        this.$globalVar.userData.distributionIncomeMode & 1
      ) {
        this.$router.push('/distributor/percentage/share');
      }
    },
    drawText(ctx, t, x, y, w, h = 99999999, lh = 36) {
      const chr = t.split('');
      let temp = '';
      const row = [];

      // ctx.font = '20px Arial';
      // ctx.fillStyle = 'black';
      // ctx.textBaseline = 'middle';

      for (let a = 0; a < chr.length; a++) {
        if (ctx.measureText(temp).width < w) {
          // 保留
        } else {
          if (row.length + 1 >= h) {
            temp += '...';
            break;
          }
          row.push(temp);
          temp = '';
        }
        temp += chr[a];
      }

      row.push(temp);

      for (let b = 0; b < row.length; b++) {
        ctx.fillText(row[b], x, y + b * lh);
        if (b + 1 >= h) return;
      }
    },
    async onSpreadQrcode() {
      if (!this.QRCodeSrc) {
        const canvas = this.$refs.graphicCanvas;
        const ctx = canvas.getContext('2d');
        const json = await Buyer.Mb.Distributor.createDistributorQrcode();
        const qrcode = await this.$utils.Comm.creatQRCode(json.data.data || '');
        // this.distributor.avatar =
        //     'http://oss.goefresh.ca/upload/sys/siteSetting/77d3b56a39a74a68acaaccb77d60b043.png';
        const imgs = await this.$utils.Comm.loadImgs([
          qrcode,
          this.distributor.avatar,
        ]);

        const { width, height } = canvas;

        ctx.fillStyle = '#fff';
        ctx.fillRect(0, 0, width, height);

        ctx.drawImage(imgs[0], 0, 0, width, width);

        ctx.fillRect(width / 2 - 50, width / 2 - 50, 100, 100);
        ctx.drawImage(imgs[1], width / 2 - 45, width / 2 - 45, 90, 90);

        ctx.font = '40px Helvetica';
        ctx.fillStyle = '#000';
        ctx.textAlign = 'center';
        this.drawText(
          ctx,
          this.distributor.distributorName,
          width / 2,
          height - 70,
          width,
        );
        ctx.font = '26px Helvetica';
        ctx.fillStyle = '#999';
        this.drawText(ctx, '长按识别二维码', width / 2, height - 30, width);
        this.QRCodeSrc = canvas.toDataURL();
      }
      // const json = await Buyer.Mb.Distributor.createDistributorQrcode();
      // const url = await this.$utils.Comm.creatQRCode(json.data.data || '');
      this.$messageBox.alert(
        `<img src="${this.QRCodeSrc}" width="100%"/>`,
        '推广二维码',
      );
    },
  },
  created() {
    Buyer.Mb.Distributor.distributorDetail().then(json => {
      const result = json.data;
      this.distributor = result.data;
    });
  },
};
</script>

<style lang="scss" scoped>
.expect-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  div {
    flex: 1 1 1%;
    text-align: center;
  }
}
</style>
