<!-- Created by henian.xu on 2018/12/27. -->

<template>
  <Page>
    <Container ref="container">
      <div class="distributor-detail">
        <div class="img-obj">
          <div
            class="inner"
            :style="`background-image: url('${cardData.levelCardUrl || $defaultImg}');`"
          />
        </div>
        <div class="header">
          <div class="label">
            {{ cardData.levelName }}
          </div>
        </div>
        <div class="footer bor-t">
          <div class="label">
            代理费
          </div>
          <div class="price">
            {{ cardData.agentFee | price }}
          </div>
        </div>
      </div>

      <XForm
        ref="form"
        v-if="!isDistributor"
        :rules="formRules"
        :model="formData"
      >
        <form-item
          prop="invitationCode"
          :class="+$globalVar.shopData.isMustInvitationCode?'required':''"
          label="邀请码："
        >
          <input
            type="text"
            v-model="formData.invitationCode"
            placeholder="请输入"
            :readonly="!!orderId"
          >
        </form-item>
        <form-item
          label="优惠码："
        >
          <input
            type="text"
            v-model="formData.promoCode"
            placeholder="请输入"
            :readonly="!!orderId"
          >
        </form-item>
        <form-item
          prop="distributorName"
          class="required"
          label="姓名："
        >
          <input
            type="text"
            v-model="formData.distributorName"
            placeholder="请输入"
            :readonly="!!orderId"
          >
        </form-item>
        <!--<form-item
                    class="required"
                    label="性别："
                >
                    <select
                        v-model="formData.distributorSex"
                    >
                        <option value="">请选择</option>
                        <option value="1">男</option>
                        <option value="2">女</option>
                    </select>
                </form-item>
                <form-item
                    class="required"
                    label="出生日期："
                >
                    <datetime
                        :min-year="1950"
                        class="datetime"
                        v-model="formData.distributorBirthday"
                    >{{formData.distributorBirthday||'请选择出生日期'}}
                    </datetime>
                </form-item>
                <form-item
                    class="required"
                    label="手机号："
                >
                    <input
                        type="text"
                        v-model="formData.distributorTel"
                        placeholder="请输入"
                    >
                </form-item>-->
        <form-item
          prop="wechatId"
          class="required"
          label="微信号："
        >
          <input
            type="text"
            v-model="formData.wechatId"
            placeholder="请输入"
            :readonly="!!orderId"
          >
        </form-item>
        <form-item label="邮箱：">
          <input
            type="text"
            v-model="formData.distributorEmail"
            placeholder="请输入"
            :readonly="!!orderId"
          >
        </form-item>
        <!--<form-item
                    class="required"
                    label="所在区域："
                >
                    <region-picker
                        v-model="formData.distributorRegionId"
                    />
                </form-item>
                <form-item
                    class="required"
                    label="家庭地址："
                >
                    <x-input
                        v-model="formData.distributorAddress"
                        placeholder="如街道，楼层，门牌号等"
                    />
                </form-item>-->
        <!--<form-item
                    class="required"
                    label="邮编："
                >
                    <input type="text"
                           v-model="formData.distributorPostcode"
                           placeholder="请输入"
                    >
                </form-item>-->
      </XForm>
    </Container>
    <PageBar>
      <Button
        v-if="orderId"
        @click="onCancel"
        theme="second"
        label="取消订单"
      />
      <Button
        @click="()=>{onSubmit()}"
        theme="main"
        label="立即购买"
      />
      <PayPopup
        ref="payPopup"
        :module="3"
        :order-ids="orderId"
      />
    </PageBar>
  </Page>
</template>

<script>
import { Buyer } from '@/api';
import pageMixin from '@/mixins/layout/page';
export default {
  name: 'BuyCard',
  mixins: [pageMixin],
  data() {
    return {
      cardData: {},
      orderData: {},
      orderId: 0, // 生成订单后返回的订单 id
      formData: {
        distributorLevelId: '',
        invitationCode: '',
        promoCode: '',
        empNo: '', // 工卡号
        distributorName: '', // 姓名
        distributorSex: '0', // 姓名
        distributorTel: '', // 电话
        wechatId: '', // 微信号
        distributorEmail: '', // 邮箱地址
        distributorAddress: '', // 邮寄地址
        distributorPostcode: '', // 邮编
        distributorBirthday: '', // 出生日期
        distributorRegionId: '', // 请选择区域
        bankCardNo: '', // 银行卡号
        bankBranchCode: '',
        bankCode: '',
        bankCard: '',
      },
      formRules: {
        invitationCode: {
          required: +this.$globalVar.shopData.isMustInvitationCode,
          message: '不能为空',
        },
        distributorName: {
          required: true,
          message: '不能为空',
        },
        wechatId: {
          required: true,
          message: '不能为空',
        },
      },
    };
  },
  computed: {
    distributorLevelId() {
      return this.$route.params.distributorLevelId;
    },
    $$payPopup() {
      return this.$refs.payPopup || null;
    },
  },
  beforeRouteEnter(to, from, next) {
    const { distributorLevelId } = to.params;
    Promise.all([
      Buyer.Mb.Distributor.distributorLevelDetail(distributorLevelId),
      Buyer.Mb.Distributor.getBuyerAgentFeeOrderDetail(distributorLevelId),
    ]).then(jsons => {
      next(vm => {
        vm.cardData = jsons[0].data.data;
        vm.orderData = jsons[1].data.data || {};
        const { formData, orderData } = vm;
        vm.orderId = orderData.id || 0;
        Object.keys(formData).forEach(key => {
          formData[key] = orderData[key] || formData[key];
        });
      });
    });
  },
  methods: {
    getDetailData(id = this.distributorLevelId) {
      return this.$api.Buyer.Mb.Distributor.distributorLevelDetail(id).then(
        json => {
          this.cardData = json.data.data;
        },
      );
    },
    async onSubmit() {
      if (this.orderId) {
        this.onPay();
        return;
      }
      if (!this.isDistributor) {
        await this.$refs['form'].validate();
      }
      const data = {
        ...this.formData,
        distributorLevelId: this.distributorLevelId,
      };
      return this.$api.Buyer.Mb.Distributor.createBuyerAgentFeeOrder(data).then(
        json => {
          const res = json.data.data;
          console.log(res);
          if (+res.orderStatus === 10) {
            this.orderId = res.id;
            this.onPay();
          } else {
            this.$globalVar.routerEdReplace = true;
            this.$router.replace('/distributor/home');
          }
        },
      );
    },
    onCancel() {
      this.$api.Buyer.Mb.Distributor.cancelBuyerAgentFeeOrder(
        this.orderId,
      ).then(json => {
        const res = json.data;
        this.orderId = 0;
        this.formData = JSON.parse(JSON.stringify(this.initFormData));
        this.$messageBox.tips(res.msg);
      });
    },
    onPay() {
      this.$$payPopup.open();
    },
  },
  created() {
    // this.getDetailData();
    Object.defineProperty(this, 'initFormData', {
      value: JSON.parse(JSON.stringify(this.formData)),
    });
  },
};
</script>

<style lang="scss">
.distributor-detail {
  margin-bottom: $margin;
  > .img-obj {
    width: 100%;
    > .inner {
      width: 100%;
      height: 0;
      padding-top: 45%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  > .header {
    padding: $padding;
    background-color: #fff;
  }
  > .footer {
    padding: $padding;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
  }
}
</style>
